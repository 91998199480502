@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    height: 100%;
    overflow-y: hidden;
    @apply bg-brand-border;
}

h2 {
    @apply inline-block text-gray-200 uppercase font-semibold tracking-wider;
    /* @apply w-fit bg-gradient-to-r from-white to-green-500 bg-clip-text text-transparent; */
    font-size: 1.1rem;
    margin-top: 3px;
    margin-bottom: 5px;
}

/* Grid layout */
.trading-layout {
    width: 100vw;
    /* - navbar, subheader, footer */
    height: calc(100dvh - 48px - 40px - 64px);
}

.xplortrader-layout {
    width: 100vw;
    /* - navbar, subheader, account usages, footer, */
    /* height: calc(100dvh - 48px - 40px - 26.67px - 64px); */
    height: calc(100dvh - 48px - 40px - 64px);
}

.mosaic-split {
    display: none;
}

@media (min-width: 640px) {
    .trading-layout {
        width: 100vw;
        /* - navbar, subheader, footer */
        height: calc(100dvh - 48px - 40px - 24px);
    }

    .xplortrader-layout {
        width: 100vw;
        /* - navbar, subheader, account usages, footer, */
        /* height: calc(100dvh - 48px - 40px - 26.67px - 24px); */
        height: calc(100dvh - 48px - 40px - 24px);
    }
}

@media (min-width: 1024px) {
    .trading-layout {
        width: 100vw;
        /* - navbar, footer */
        height: calc(100dvh - 48px - 24px);
    }

    .xplortrader-layout {
        width: 100vw;
        /* - navbar, account usages, footer, */
        /* height: calc(100dvh - 48px - 26.67px - 24px); */
        height: calc(100dvh - 48px - 24px);
    }

    .mosaic-split {
        display: block;
    }
}

.pop-up-market-list {
    /* - navbar, subheader, footer, borders */
    height: calc(100dvh - 48px - 40px - 64px - 3px);
}

.trader-pop-up-market-list {
    /* - navbar, subheader, account usages, footer, borders */
    /* height: calc(100dvh - 48px - 40px - 26.67px - 64px - 3px); */
    height: calc(100dvh - 48px - 40px - 64px - 3px);
}

@media (min-width: 640px) {
    .pop-up-market-list {
        /* - navbar, subheader, footer, borders */
        height: calc(100dvh - 48px - 40px - 24px - 3px);
    }

    .trader-pop-up-market-list {
        /* - navbar, subheader, account usages, footer, borders */
        /* height: calc(100dvh - 48px - 40px - 26.67px - 24px - 3px); */
        height: calc(100dvh - 48px - 40px - 24px - 3px);
    }
}

.historic-search-empty {
    /* background:  center center / 80px repeat rgba(0, 0, 0, 0.1); */
}

.trade-historic-search-empty {
    opacity: 0.8;
    background: repeating-linear-gradient(45deg, #222222, #222222 5px, #262626 5px, #262626 25px);
}

.ticker-widget-header:hover {
    background: linear-gradient(to bottom, #404040, var(--color-brand-background-dark));
}

/* Loading spinner */
.loader {
    border-top-color: #3498db;
    animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Hide sort index from column header */
.ag-header-cell-label .ag-sort-indicator-icon.ag-sort-order {
    display: none;
}

.ag-header {
    @apply bg-brand-background-dark;
}

.ag-header-cell {
    padding-left: 6px;
    padding-right: 0px;
}

.ag-header-cell:hover {
    cursor: all-scroll;
}

.ag-header-cell-menu-button {
    display: none;
}

.ag-cell {
    padding-left: 6px;
    padding-right: 0px;
}

.ag-sort-indicator-icon {
    margin-right: 6px;
}

.ag-row-odd {
    @apply bg-brand-background-dark;
}

h1,
h2,
h3,
h4 {
    @apply font-secondary;
}

.toast {
    align-items: flex-start !important;
}

.toast > div:first-child {
    margin-top: 8px;
}

.custom-check-mark {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #61d345;
    transform: scale(1) rotate(45deg);
    opacity: 1;
}

.custom-check-mark:after {
    content: '';
    box-sizing: border-box;
    opacity: 1;
    position: absolute;
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-color: #fff;
    bottom: 6px;
    left: 6px;
    height: 10px;
    width: 6px;
}

.custom-check-mark {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #61d345;
    transform: scale(1) rotate(45deg);
}

.custom-check-mark:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-color: #fff;
    bottom: 6px;
    left: 6px;
    height: 10px;
    width: 6px;
}

.custom-error-icon {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #ff4b4b;
    position: relative;
    transform: scale(1) rotate(45deg);
}

.custom-error-icon::after {
    content: '';
    position: absolute;
    border-radius: 3px;
    background: #fff;
    transform: scale(1);
    bottom: 9px;
    left: 4px;
    height: 2px;
    width: 12px;
}

.custom-error-icon::before {
    content: '';
    position: absolute;
    border-radius: 3px;
    background: #fff;
    transform: scale(1) rotate(90deg);
    bottom: 9px;
    left: 4px;
    height: 2px;
    width: 12px;
}

.table-resizer {
    position: absolute;
    right: 8px;
    top: 10px;
    height: 10px;
    width: 1.5px;
    cursor: ew-resize;
    user-select: none;
    touch-action: none;
    background: rgba(255, 255, 255, 0.125);
}

.table-resizer.isResizing {
    @apply bg-brand-primary;
    opacity: 1;
}

.filler-background {
    height: inherit;
    transition: 'width 2s ease-i-out';
    background: linear-gradient(90deg, var(--color-brand-primary), rgb(255, 255, 0) 40%, var(--color-brand-red));
}

.filler {
    transition: 'width 2s ease-i-out';
    height: inherit;
    border-radius: inherit;
    overflow: hidden;
}
