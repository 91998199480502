/**
 * @license
 * Copyright 2019 Kevin Verdieck, originally developed at Palantir Technologies, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.mosaic {
    height: 100%;
    width: 100%;
}
.mosaic,
.mosaic > * {
    box-sizing: border-box;
}
.mosaic .mosaic-zero-state {
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    width: auto;
    height: auto;
    z-index: 1;
}
.mosaic-root {
    position: absolute;
    top: 1.5px;
    right: 1.5px;
    bottom: 1.5px;
    left: 1.5px;
}
.mosaic-split {
    position: absolute;
    z-index: 1;
    touch-action: none;
}
.mosaic-split:hover {
    background: black;
}
.mosaic-split .mosaic-split-line {
    position: absolute;
}
.mosaic-split.-row {
    margin-left: -1.5px;
    width: 3px;
    cursor: ew-resize;
}
.mosaic-split.-row .mosaic-split-line {
    top: 0;
    bottom: 0;
    left: 1.5px;
    right: 1.5px;
}
.mosaic-split.-column {
    margin-top: -1.5px;
    height: 3px;
    cursor: ns-resize;
}
.mosaic-split.-column .mosaic-split-line {
    top: 1.5px;
    bottom: 1.5px;
    left: 0;
    right: 0;
}
.mosaic-tile {
    position: absolute;
    margin: 1.5px;
}
.mosaic-tile > * {
    height: 100%;
    width: 100%;
}
.mosaic-drop-target {
    position: relative;
}
.mosaic-drop-target.drop-target-hover .drop-target-container {
    display: block;
}
.mosaic-drop-target.mosaic > .drop-target-container .drop-target.left {
    right: calc(100% - 10px);
}
.mosaic-drop-target.mosaic > .drop-target-container .drop-target.right {
    left: calc(100% - 10px);
}
.mosaic-drop-target.mosaic > .drop-target-container .drop-target.bottom {
    top: calc(100% - 10px);
}
.mosaic-drop-target.mosaic > .drop-target-container .drop-target.top {
    bottom: calc(100% - 10px);
}
.mosaic-drop-target .drop-target-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
}
.mosaic-drop-target .drop-target-container.-dragging {
    display: block;
}
.mosaic-drop-target .drop-target-container .drop-target {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    border: 2px solid black;
    opacity: 0;
    z-index: 5;
}
.mosaic-drop-target .drop-target-container .drop-target.left {
    right: calc(100% - 30%);
}
.mosaic-drop-target .drop-target-container .drop-target.right {
    left: calc(100% - 30%);
}
.mosaic-drop-target .drop-target-container .drop-target.bottom {
    top: calc(100% - 30%);
}
.mosaic-drop-target .drop-target-container .drop-target.top {
    bottom: calc(100% - 30%);
}
.mosaic-drop-target .drop-target-container .drop-target.drop-target-hover {
    opacity: 1;
}
.mosaic-drop-target .drop-target-container .drop-target.drop-target-hover.left {
    right: calc(100% - 50%);
}
.mosaic-drop-target .drop-target-container .drop-target.drop-target-hover.right {
    left: calc(100% - 50%);
}
.mosaic-drop-target .drop-target-container .drop-target.drop-target-hover.bottom {
    top: calc(100% - 50%);
}
.mosaic-drop-target .drop-target-container .drop-target.drop-target-hover.top {
    bottom: calc(100% - 50%);
}
.mosaic-window,
.mosaic-preview {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}
.mosaic-window .mosaic-window-toolbar,
.mosaic-preview .mosaic-window-toolbar {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    height: 30px;
    /* background: var(--color-brand-primary-light); */
    /* background: #a3a3a3; */
    background: var(--color-brand-background-dark);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.mosaic-window .mosaic-window-toolbar.draggable,
.mosaic-preview .mosaic-window-toolbar.draggable {
    cursor: move;
}
.mosaic-window .mosaic-window-title,
.mosaic-preview .mosaic-window-title {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 8px;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-height: 18px;
}
.mosaic-window .mosaic-window-controls,
.mosaic-preview .mosaic-window-controls {
    display: flex;
    height: 100%;
}
.mosaic-window .mosaic-window-controls .separator,
.mosaic-preview .mosaic-window-controls .separator {
    height: 20px;
    border-left: 1px solid black;
    margin: 5px 4px;
}
.mosaic-window .mosaic-window-body,
.mosaic-preview .mosaic-window-body {
    position: relative;
    flex: 1;
    height: 0;
    background: var(--color-brand-primary-light);
    z-index: 1;
    overflow: hidden;
}
.mosaic-window .mosaic-window-additional-actions-bar,
.mosaic-preview .mosaic-window-additional-actions-bar {
    position: absolute;
    top: 30px;
    right: 0;
    bottom: initial;
    left: 0;
    height: 0;
    overflow: hidden;
    background: var(--color-brand-primary-light);
    justify-content: flex-end;
    display: flex;
    z-index: 3;
}
.mosaic-window .mosaic-window-additional-actions-bar .bp4-button,
.mosaic-preview .mosaic-window-additional-actions-bar .bp4-button {
    margin: 0;
}
.mosaic-window .mosaic-window-additional-actions-bar .bp4-button:after,
.mosaic-preview .mosaic-window-additional-actions-bar .bp4-button:after {
    display: none;
}
.mosaic-window .mosaic-window-body-overlay,
.mosaic-preview .mosaic-window-body-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: var(--color-brand-primary-light);
    display: none;
    z-index: 2;
}
.mosaic-window.additional-controls-open .mosaic-window-additional-actions-bar,
.mosaic-preview.additional-controls-open .mosaic-window-additional-actions-bar {
    height: 30px;
}
.mosaic-window.additional-controls-open .mosaic-window-body-overlay,
.mosaic-preview.additional-controls-open .mosaic-window-body-overlay {
    display: block;
}
.mosaic-window .mosaic-preview,
.mosaic-preview .mosaic-preview {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
    border: 1px solid black;
    max-height: 400px;
}
.mosaic-window .mosaic-preview .mosaic-window-body,
.mosaic-preview .mosaic-preview .mosaic-window-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mosaic-window .mosaic-preview h4,
.mosaic-preview .mosaic-preview h4 {
    margin-bottom: 10px;
}
.mosaic:not(.mosaic-blueprint-theme) .mosaic-default-control.close-button:before {
    content: 'Close';
}
.mosaic:not(.mosaic-blueprint-theme) .mosaic-default-control.split-button:before {
    content: 'Split';
}
.mosaic:not(.mosaic-blueprint-theme) .mosaic-default-control.replace-button:before {
    content: 'Replace';
}
.mosaic:not(.mosaic-blueprint-theme) .mosaic-default-control.expand-button:before {
    content: 'Expand';
}
.mosaic.mosaic-blueprint-theme {
    background: var(--color-brand-primary);
}
.mosaic.mosaic-blueprint-theme .mosaic-zero-state {
    background: var(--color-brand-primary);
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.15);
}
.mosaic.mosaic-blueprint-theme .mosaic-zero-state .default-zero-state-icon {
    font-size: 120px;
}
.mosaic.mosaic-blueprint-theme .mosaic-split:hover {
    background: none;
}
.mosaic.mosaic-blueprint-theme .mosaic-split:hover .mosaic-split-line {
    box-shadow: 0 0 0 1px var(--color-brand-primary);
}
.mosaic.mosaic-blueprint-theme.mosaic-drop-target .drop-target-container .drop-target,
.mosaic.mosaic-blueprint-theme .mosaic-drop-target .drop-target-container .drop-target {
    background: rgba(138, 187, 255, 0.2);
    border: 2px solid var(--color-brand-primary);
    transition: opacity 100ms;
    border-radius: 2px;
}
.mosaic.mosaic-blueprint-theme .mosaic-window,
.mosaic.mosaic-blueprint-theme .mosaic-preview {
    box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.15);
    border-radius: 2px;
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-toolbar,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-toolbar {
    box-shadow: 0 1px 1px rgba(17, 20, 24, 0.15);
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-toolbar.draggable:hover,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-toolbar.draggable:hover {
    /* background: linear-gradient(to bottom, var(--color-brand-background), var(--color-brand-primary-dark)); */
    /* background: linear-gradient(to bottom, var(--color-brand-primary-light), var(--color-brand-primary)); */
    background: linear-gradient(to bottom, #404040, var(--color-brand-background-dark));
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-toolbar.draggable:hover .mosaic-window-title,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-toolbar.draggable:hover .mosaic-window-title {
    color: #e5e5e5;
    /* color: var(--color-brand-background); */
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-title,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-title {
    font-weight: 600;
    color: #e5e5e5;
    /* color: var(--color-brand-background); */
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-controls .separator,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-controls .separator {
    border-left: 1px solid var(--color-brand-background);
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-controls .bp4-button,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-controls .bp4-button,
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-controls .bp4-button:before,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-controls .bp4-button:before {
    color: #738091;
}
.mosaic.mosaic-blueprint-theme .mosaic-window .default-preview-icon,
.mosaic.mosaic-blueprint-theme .mosaic-preview .default-preview-icon {
    font-size: 72px;
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-body,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-body {
    border-top-width: 0;
    background: var(--color-brand-background);
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-additional-actions-bar,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-additional-actions-bar {
    transition: height 250ms;
    box-shadow: 0 1px 1px rgba(17, 20, 24, 0.15);
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-additional-actions-bar .bp4-button,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-additional-actions-bar .bp4-button,
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-additional-actions-bar .bp4-button:before,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-additional-actions-bar .bp4-button:before {
    color: #738091;
}
.mosaic.mosaic-blueprint-theme .mosaic-window.additional-controls-open .mosaic-window-toolbar,
.mosaic.mosaic-blueprint-theme .mosaic-preview.additional-controls-open .mosaic-window-toolbar {
    box-shadow: 0 1px 0 0 0 0 1px rgba(17, 20, 24, 0.15);
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-preview,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-preview {
    border: 1px solid #8f99a8;
}
.mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-preview h4,
.mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-preview h4 {
    color: #404854;
}
.mosaic.mosaic-blueprint-theme.bp4-dark {
    background: #252a31;
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-zero-state {
    background: #383e47;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-split:hover .mosaic-split-line {
    box-shadow: 0 0 0 1px var(--color-brand-primary-dark);
}
.mosaic.mosaic-blueprint-theme.bp4-dark.mosaic-drop-target .drop-target-container .drop-target,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-drop-target .drop-target-container .drop-target {
    background: rgba(33, 93, 176, 0.2);
    border-color: var(--color-brand-primary-dark);
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window-toolbar,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window-additional-actions-bar {
    background: #383e47;
    box-shadow: 0 1px 1px rgba(17, 20, 24, 0.4);
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-window-toolbar.draggable:hover,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-window-toolbar.draggable:hover {
    background: linear-gradient(to bottom, #404854, #383e47);
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-window-toolbar.draggable:hover .mosaic-window-title,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-window-toolbar.draggable:hover .mosaic-window-title {
    color: #e5e5e5;
    /* color: var(--color-brand-primary-dark); */
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-window-title,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-window-title {
    color: #e5e5e5;
    /* color: var(--color-brand-primary-dark); */
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-window-controls .separator,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-window-controls .separator {
    border-color: #5f6b7c;
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-window-controls .bp4-button,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-window-controls .bp4-button,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-window-controls .bp4-button:before,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-window-controls .bp4-button:before {
    color: #abb3bf;
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-window-body,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-window-body {
    background: #252a31;
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-window-additional-actions-bar .bp4-button,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-window-additional-actions-bar .bp4-button,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-window-additional-actions-bar .bp4-button:before,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-window-additional-actions-bar .bp4-button:before {
    color: #e5e5e5;
    /* color: var(--color-brand-primary-dark); */
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window.additional-controls-open .mosaic-window-toolbar,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview.additional-controls-open .mosaic-window-toolbar {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-preview,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-preview {
    border-color: #5f6b7c;
}
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-window .mosaic-preview h4,
.mosaic.mosaic-blueprint-theme.bp4-dark .mosaic-preview .mosaic-preview h4 {
    color: #e5e5e5;
    /* color: var(--color-brand-primary-dark); */
}

/* temporary fix for tooltip */

/* .mosaic-window-toolbar {
    z-index: 1 !important;
}

.mosaic-window-body {
    overflow: visible !important;
} */

@media (max-width: 1023px) {
    .mosaic-root {
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
    }
    .mosaic-split.-row {
        margin-left: 0px;
    }
    .mosaic-split.-row .mosaic-split-line {
        left: 0px;
        right: 0px;
    }
    .mosaic-split.-column {
        margin-top: 0px;
    }
    .mosaic-split.-column .mosaic-split-line {
        top: 0px;
        bottom: 0px;
    }
    .mosaic-tile {
        margin: 1px;
    }
}
