::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    @apply bg-brand-primary;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-brand-primary-dark;
}

::-webkit-scrollbar-thumb:active {
    @apply bg-brand-primary-darker;
}

/* Color scrollbar red when Order Switch is set to Sell */

.sell {
    scrollbar-color: var(--color-brand-red) rgba(0, 0, 0, 0.3);
}

.sell::-webkit-scrollbar-thumb {
    @apply bg-brand-red;
    border-radius: 0px;
}

.sell::-webkit-scrollbar-thumb:hover {
    @apply bg-brand-red-dark;
}

.sell::-webkit-scrollbar-thumb:active {
    @apply bg-brand-red-darker;
}

/* Set scrollbar to none for Order Book section */

.order-book {
    scrollbar-width: none;
}

.order-book::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.3);
}

* {
    scrollbar-color: var(--color-brand-primary) rgba(0, 0, 0, 0.3);
    scrollbar-width: thin;
}

/* Set scrollbar for all modals tab-list */

.tab-list {
    scrollbar-width: none;
}

.tab-list::-webkit-scrollbar {
    height: 1px;
    width: 1px;
}

/* Set scrollbar for account usages */

.account-usages::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

/* Set scrollbar for ticker order book */

.ticker-order-book::-webkit-scrollbar {
    height: 2px;
    width: 4px;
}

.ticker-order-book::-webkit-scrollbar-thumb {
    background-color: rgb(82 82 82);
    border-radius: 0px;
}

.ticker-order-book::-webkit-scrollbar-thumb:hover {
    background-color: rgb(64 64 64);
}

.ticker-order-book::-webkit-scrollbar-thumb:active {
    background-color: rgb(64 64 64);
}
